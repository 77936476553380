<template>
  <div class="todo-app-list">
    <ekle-headers
      :title="'Tarih ve Fiyat Bilgisi'"
      :baslik="form.urun_adi"
      @mqShallShowLeftSidebar="$emit('mqShallShowLeftSidebar', $event)"
      @removeProduct="$emit('removeProduct', $event)"
    />
    <vue-perfect-scrollbar :settings="scrollbarSettings" class="todo-task-list-wrapper scroll-area p-2">
      <b-card-normal :title="'Dahil ve hariç hizmetlerden bahsedin.'">
        <template v-slot:body>
          <b-row>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="12">
                  <div class="d-flex justify-content-between">
                    <h4>Dahil hizmetler nelerdir.</h4>
                    <b-button
                      variant="primary"
                      size="sm"
                      squared
                      @click="handlerAddItem('dahil')"
                      v-b-tooltip.hover.top="'Dahil Hizmet Ekle'"
                    >
                      <feather-icon icon="PlusSquareIcon" size="18" class="cursor-pointer" />
                    </b-button>
                  </div>
                </b-col>
                <b-col cols="12" class="pt-1">
                  <b-list-group class="rounded-0" v-if="form.dahil_hizmet.length > 0">
                    <b-list-group-item
                      v-for="(item, i) in form.dahil_hizmet"
                      :key="i"
                      class="d-flex justify-content-between align-items-center"
                    >
                      <span class="text-success">
                        <feather-icon icon="CheckIcon" size="22" />
                        {{ handlerHizmetAdi(item.hizmet_k_no) }}
                      </span>
                      <b-button squared variant="flat-danger" class="btn-icon" @click="handlerRemove('dahil', item)">
                        <feather-icon icon="Trash2Icon" size="18" />
                      </b-button>
                    </b-list-group-item>
                  </b-list-group>
                  <b-alert variant="warning" class="text-center rounded-0" show v-else>
                    <div class="alert-body">Gösterilecek veri yok</div>
                  </b-alert>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" md="6">
              <b-row>
                <b-col cols="12">
                  <div class="d-flex justify-content-between">
                    <h4>Hariç hizmetler nelerdir.</h4>
                    <b-button
                      variant="primary"
                      size="sm"
                      squared
                      @click="handlerAddItem('haric')"
                      v-b-tooltip.hover.top="'Hariç Hizmet Ekle'"
                    >
                      <feather-icon icon="PlusSquareIcon" size="18" class="cursor-pointer" />
                    </b-button>
                  </div>
                </b-col>
                <b-col cols="12" class="pt-1">
                  <b-list-group class="rounded-0" v-if="form.haric_hizmet.length > 0">
                    <b-list-group-item
                      v-for="(item, i) in form.haric_hizmet"
                      :key="i"
                      class="d-flex justify-content-between align-items-center"
                    >
                      <span class="text-danger">
                        <feather-icon icon="XIcon" size="22" />
                        {{ handlerHizmetAdi(item.hizmet_k_no) }}
                      </span>
                      <b-button squared variant="flat-danger" class="btn-icon" @click="handlerRemove('haric', item)">
                        <feather-icon icon="Trash2Icon" size="18" />
                      </b-button>
                    </b-list-group-item>
                  </b-list-group>
                  <b-alert variant="warning" class="text-center rounded-0" show v-else>
                    <div class="alert-body">Gösterilecek veri yok</div>
                  </b-alert>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </b-card-normal>
    </vue-perfect-scrollbar>
    <urun-dahil-haric-hizmet-tanimla :openModal="openModal" :type="type" :data="form" @closeModal="closeModal = $event" />
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import EkleHeaders from '../component/ekleHeader.vue';
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import UrunDahilHaricHizmetTanimla from './dahilHaricHizmetTanimla.vue';
export default {
  components: {
    VuePerfectScrollbar,
    EkleHeaders,
    BCardNormal,
    UrunDahilHaricHizmetTanimla,
  },
  props: {
    form: {
      type: Object,
      required: true,
      default: {},
    },
    scrollbarSettings: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    show: false,
    openModal: false,
    closeModal: false,
    type: 'dahil',
  }),
  computed: {
    handlerHizmetAdi() {
      return (k_no) => {
        if (k_no) {
          return this.$store.getters.getEkstraHizmetler.find((x) => x.k_no == k_no).icerik['tr'].baslik;
        }
        return null;
      };
    },
  },
  methods: {
    handlerAddItem(type) {
      this.type = type;
      this.openModal = true;
    },
    handlerRemove(type, item) {
      const data = {
        k_no: this.form.k_no,
        type,
        hizmet_k_no: item.hizmet_k_no,
      };
      this.$swal({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$store.dispatch('urunDahilHaricHizmetSil', data).then((res) => {
            if (res.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: `Silme başarılı.`,
                },
              });
            }
          });
        }
      });
    },
  },
  watch: {
    closeModal(val) {
      this.openModal = false;
      this.closeModal = false;
    },
  },
};
</script>
<style lang="scss">
.col-form-label {
  padding-top: calc(0.438rem + 13px);
  font-weight: bold;
}
</style>
